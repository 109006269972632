import React, { FC, useState, useEffect } from 'react';
import Button from './common/Button';
import Modal from './Reimbursement/Modal';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hbspt: any;
  }
}

interface Props {
  buttonText: string;
  portalId: string;
  formId: string;
  style?: React.CSSProperties;
}

const HubspotFormOverlay: FC<Props> = ({
  buttonText,
  portalId,
  formId,
  style,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [formRendered, setFormRendered] = useState(false);

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const showFormular = {
    region: 'eu1',
    portalId,
    formId,
    target: '#hubspotForm',
  };

  const initializeHubspotForm = () => {
    if (typeof window !== 'undefined' && 'hbspt' in window) {
      window.hbspt.forms.create(showFormular);
    } else {
      setTimeout(initializeHubspotForm, 500);
    }
  };

  const renderFormular = () => {
    if (showFormular) {
      setTimeout(initializeHubspotForm, 500);
    }

    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  };

  useEffect(() => {
    if (showForm && window.hbspt && !formRendered) {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId,
        formId,
        target: '#hubspotForm',
      });
      setFormRendered(true);
    }
  }, [formId, portalId, showForm, formRendered]);

  return (
    <>
      <Modal isOpen={showForm} onRequestClose={closeForm}>
        {renderFormular()}
      </Modal>
      <Button
        onClick={openForm}
        design="bordered"
        css={{
          color: '#3D72DF',
          fontWeight: 700,
          borderColor: '#3D72DF',
        }}
        style={style}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default HubspotFormOverlay;
