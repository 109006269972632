import React, { FC, useState, MouseEvent } from 'react';
import CloseCross from '../assets/images/close-cross.png';
import Button from './common/Button';
import { ReactComponent as PlayButton } from '../assets/svgs/homepage/playbutton.svg';
import Video from './Video';

const videoStyles = `
.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video__inner {
  position: relative;
  width: 100%;
  max-width: 560px;
  height: 100%;
  max-height: 315px;
}

.video__close {
  position: absolute;
  top: -100px;
  right: 0px;
  width: 30px;
  height: 30px;
  background-image: url(${CloseCross});
  background-size: cover;
  cursor: pointer;
}


.video__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.video__content iframe {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

@media (min-width: 768px) {
  .video__inner {
    max-width: 800px;
    max-height: 450px;
  }
}
`;

interface Props {
  link: string;
  buttonType: 'button' | 'image';
  thumbnail?: string;
  buttonText?: string;
}

const VideoOverlay: FC<Props> = ({
  link,
  buttonType,
  thumbnail,
  buttonText,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    const targetElement = e.target as HTMLDivElement;
    if (targetElement.classList.contains('video')) {
      closeVideo();
    }
  };

  if (showVideo) {
    return (
      <>
        <div className="video" onClick={handleOverlayClick}>
          <div className="video__inner">
            <div className="video__close" onClick={closeVideo}></div>
            <div className="video__content">
              <Video videoSrcURL={link} playing={true} />
            </div>
          </div>
        </div>
        <style>{videoStyles}</style>
      </>
    );
  }

  const renderButton = () => {
    switch (buttonType) {
      case 'image':
        return (
          <img
            css={{
              cursor: 'pointer',
              marginBottom: 0,
            }}
            alt={buttonText}
            src={thumbnail}
            onClick={handleVideoClick}
          />
        );
      case 'button':
      default:
        return (
          <Button
            className="youtube-link"
            onClick={handleVideoClick}
            css={{
              display: 'flex',
              alignItems: 'center',
              border: '2px solid #489F9D',
              backgroundColor: '#ffffff',
              color: '#489F9D',
              margin: '5px 10px',
              fontWeight: 700,
              padding: '.5rem .7rem',
              borderRadius: 8,
            }}
          >
            <PlayButton />
            <span css={{ marginLeft: 10 }}>{buttonText}</span>
          </Button>
        );
    }
  };

  return renderButton();
};

export default VideoOverlay;
