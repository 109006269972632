import React from 'react';
import ReactModal from 'react-modal';
import { ClassNames, keyframes } from '@emotion/core';

type ModalProps = Omit<
  ReactModal.Props,
  'className' | 'htmlOpenClassName' | 'bodyOpenClassName' | 'overlayClassName'
>;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#___gatsby');

const Modal: React.FC<ModalProps> = ({ children, ...rest }) => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          {...rest}
          overlayClassName={css`
            padding: 1rem;
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            overflow: auto;
            vertical-align: middle;
            z-index: 1000;
            opacity: 0;
            will-change: opacity;
            animation: ${fadeIn} 0.2s ease both;
          `}
          className={css`
            position: relative;
            padding: 2.25rem;
            outline: none;
            margin: auto;
            width: 100%;
            max-width: 560px;
            background-color: white;
            border-radius: 15px;
            padding: 1rem;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            background: #fff;
          `}
        >
          {children}
        </ReactModal>
      )}
    </ClassNames>
  );
};

export default Modal;
